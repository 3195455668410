$icons: (
	'market-checked' : $maket-checked,
	'market-car' : $market-car,
	'next-banner' : $next-banner,
	'prev-banner' : $prev-banner,
	'telefone-t' : $telefone-t,
	'users' : $users,
	'gift' : $gift,
	'envelopes' : $envelopes
);

.icon{
	background-image:url(#{$imgs}/sprite.png);
	display: inline-block;
}

@each $nome,$data in $icons{
	.#{$nome}{
		@include sprite($data,inline-block);
	}
}