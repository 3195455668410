body{
	font-size:15px;
}
a{
	color:inherit;
}
.paginacao{
	text-align: center;
}
.title-internas{
	margin-top:0;
}
.title-adorno-bottom{
	font-family:'playfair_display_bold';
	color:#493B2D;
	font-size:26px;

	&:after{
		content:'';
		height:13px;
		display: block;
		width:232px;
		background: url(../imagens/estrutural/adorto-titulo-right.png);
		margin-top:10px;
	}
}

.title-internas{
	border-bottom: 1px solid #000;
	font-size:15px;

	span{
		display: inline-block;
		padding:0 10px 5px 0;
		background: #fff;
		margin-bottom: -5px;
	}
}
.titles{
	font-size:20px;
}

.content__editable{
	img{
		max-width: 100%;
		height: auto !important;
	}

	&::after{
		content:'';
		display: table;
		clear: both;
	}
}