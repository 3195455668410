@media (min-width: $screen-sm-min) and (max-width:$screen-sm-max){
	// Teblet -   > 768; < 992]

	.itens-topo{
		@include prefixos(justify-content,flex-end);
		margin-bottom:20px;
	}

	#categorias-topo{

		&.active{
			.content,
			&:before{
				left:0;
			}
		}

		&:before{
			content:'';
			position:fixed;
			height:100%;
			width:100%;
			top:0;
			z-index:998;
			background:rgba(#000,0.6);
			left:-100%;
			@include transition(all 0.3s linear);
		}

		.content{
			position:fixed;
			width:220px;
			height:100%;
			background:#fff;
			top:0;
			left:-300px;
			z-index:999;
			box-shadow: 0 0 8px #000;
			overflow:auto;
			@include transition(all 0.3s linear);

			> ul {

				&:last-child{
					border-bottom:1px solid #ccc;
				}

				> li{
					padding-right:40px;
					@include clearfix;

					border-top:1px solid #ccc;

					.fa{
						width:40px;
						float:left;
						padding:9px 12px;
						border-left:1px solid #ccc;
						float:right;
						margin-right:-40px;

						&:before{
							content: '';
						}
					}
				}
			}
		}

		.subcat{
			display: none;
			background:#FDF7E4;
			margin-right:-40px;
			border-top:1px solid #ccc;

			a{
				display: block;
				padding:6px 12px;
			}

			li:hover,
			.active{
				background:#EDE6D3;
			}

			&.in{
				display:block;
			}
		}
	}

	.box-bege{
		padding:0 10px;
		display:flex;

		.login{
			padding-left:40px;

			.icon{
				width:40px;
				float:left;
				margin-left:-40px;
				margin-top:5px;
			}
		}

		.carrinho{
			white-space:nowrap;
			margin-left:30px;
			margin-right:30px;
		}
	}

	.newsletter{
		.form-group{
			width:calc(50% - 15px);
			display: inline-block;

			& + .form-group{
				margin-left:15px;
			}
		}
	}

	.box-destaque{
		display:flex;

		.foto{
			width:360px;
		}

		.description{
			width: calc(100% - 360px);
		}
	}

	.vitrine-index{
		.produto-vitrine{
			width:33.333%;
			float:left;

			&:nth-child(3) ~ .produto-vitrine{
				border-top:1px solid #ccc;
				padding-top:30px;
			}
		}
	}

	.sociais-footer{
		text-align:center;

		.img-responsive{
			margin-left:auto;
			margin-right:auto;
		}
	}

	.gv8{
		float:left;
		margin-top:10px;
	}

	.creditos{
		text-align:right;
	}

	.table-status-compra{
		.col{
			float:left;
		}

		.col-3{
			width:33.333%;
		}
	}
}