.table-meus-pedidos{
	border:1px solid rgba(#000,0.1);

	thead{
		color:#FFF;
		background:#353335;
	}

	thead > tr >th,
	tbody > tr >td,
	tfoot > tr >td{
		&:last-child{
			border-left:1px solid rgba(#000,0.1);
		}
	}

	.thumb-prod{
		width:120px;
		float:left;
		margin-right:15px;
	}

	&.no-bc{
		font-size:15px;

		thead > tr >th,
		tbody > tr >td,
		tfoot > tr >td{
			&:last-child{
				border-left:none;
			}
		}

		tbody > tr:hover{
			background: #ddd;
		}

		tbody > tr ,
		tfoot > tr {
			background:#EEEEEE;
			border-bottom:2px solid #fff;
			border-top:2px solid #fff;
		}

		a.fa-stack{
			color:#D3AC4D;

			&:hover{
				color: lighten(#D3AC4D, 10%);
			}

			.fa-stack-1x{
				color:#fff;
			}
		}
	}

	.thumb-prod{
		width:120px;
	}

	.input-group{
		max-width:420px;
	}

	.radio-label{
		position:relative;
		padding-left:20px;

		input{
			position:absolute;
			left:0;
		}

	}

	.calc-qnt{
		.form-control{
			height:41px;
		}
	}

	.input-group-btn.btn-group-vertical{


		.btn{
			padding:0 10px;
			@include button-variant(darken(#8E654F,10%),#D3CAB1,#D3CAB1);

			& + .btn{
				border-top:1px solid rgba(#000,0.2)
			}
		}
	}
}

.msgs{
	max-width:100%;
	top:10%;
	position:fixed;
	padding: 0 50px;
	right:0;
	z-index:1000;

	.msg{
		padding:10px;
		border:1px solid transparent;
		@include prefixos(align-items,center);
		max-width:320px;
		box-shadow:0 0 4px #000;
		margin-bottom:10px;
		display:none;

		.fa{
			margin-right:10px;
		}

		&.msg-success{
			background:#1EB01E;
			color:#fff;
		}

		&.msg-error{
			background: #FC2020;
			color:#fff;
		}

		&.active{
			@include animation(rubberBand 0.8s linear);
			display: flex;
		}
	}
}

.btn-carrinho{
	@include button-variant(#FFF,#D3AC4D,#D3AC4D);
}