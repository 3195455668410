@import "./_popup.scss";

.rodape{
	background:#353336;
	color:#fff;
	padding:30px 0;
	font-size:12px;

	h1{
		font-size:13px;
		font-weight:bold;
		margin:0;
	}

	ul{
		padding:0;
		list-style:none;

		li:before{
			content:'- ';
		}
	}

	.creditos{
		border-top: 2px solid #fff;
		padding-top:10px;
		max-width:686px;
		margin-left:auto;
		margin-right:auto;
	}

	.gv8{
		width:76px;
		height:27px;
		background: url(#{$imgs}/gv8.png);
		display: block;
	}

	.midia{
		color:#000;
		display: inline-block;
		padding:5px 10px;
		background:#fff;
		margin-bottom:10px;

		&:hover{
			background:#eee;
			color:$brand-success;
		}
	}

	.sociais-footer{
		font-size:14px;
	}

	.group-m{
		margin-left:5px;

		.midia{
			border-right:1px solid #ccc;

		}

		span{
			display: inline-block;
			padding:5px 10px;
			background:#fff;
			color:#000;
		}
	}
}