.vitrine-index{
	border-radius:20px;
	padding:30px;
	background:#FFF;
	box-shadow: 0 0 12px rgba(#000,0.6);

	@include clearfix;

	.produto-vitrine{
		padding:0 15px 0 15px;
		margin-bottom:30px;
		text-align:center;

		.foto{
			width: 170px;
			height:150px;
			border-top:6px solid  #E9D7A5;
			display: flex;
			@include prefixos(justify-content,center);
			@include prefixos(align-items,center);
			margin-bottom:10px;
			margin-left:auto;
			margin-right:auto;
		}

		.desc{
			@include resumo(14px,1.3,4);
			margin-top:10px;
			margin-bottom:10px;
			max-width:170px;
			margin-left:auto;
			margin-right:auto;
		}
	}

	.price span{
		display: inline-block;
		padding: 6px 12px;
		background: #E9D7A5;
		color: #353335;
		font-family: 'playfair_display_bold';
		font-size: 17px;
	}

	.btn-mais-informacoes{
		display: inline-block;
		font-size: 13px;
		color: #353335;
		background-color: #E9D7A5;
		border-color: #E9D7A5;
		border-radius:0;
		width:170px;
	}
}