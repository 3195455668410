.textura-bag{
	background: url(#{$imgs}/textura-corpo.png);
	padding:25px 0;
	box-shadow:0 4px 4px rgba(#000,0.4);

	h1{
		font-family: 'playfair_display_bold';
		font-size:26px;
		color:#493B2D;
		text-align:center;
		margin-bottom:20px;
		overflow:hidden;

		span{
			display: inline-block;
			padding:0 10px;
			position:relative;

			&:after,
			&:before{
				content:'';
				display: inline-block;
				width:232px;
				height:13px;
				position:absolute;
				top:calc(50% - 6.5px);
			}

			&:before{
				right:100%;
				background:url(#{$imgs}/adorto-titulo-right.png);
			}

			&:after{
				left:100%;
				background:url(#{$imgs}/adorto-titulo-left.png);
			}
		}
	}
}