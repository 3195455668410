.wrapper{
	min-height:100vh;

	padding-bottom: 104px;
	position:relative;

	&:after{
		content:'';
		position:absolute;
		width:100%;
		height:44px;
		bottom:0;
		background: url(#{$imgs}/base-wrapper.jpg) center top;
	}
}

html{
	overflow-x:hidden;
}

.container{
	max-width:1000px;
}