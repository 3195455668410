.box-destaque{
	max-width:864px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:30px;

	.foto{
		background:#fff;

		display:flex;
		padding:10px;

		@include prefixos(justify-content,center);
		@include prefixos(align-items,center);

		.picture{
			position:relative;
			width:100%;
			padding-bottom:100%;

			.wrapp{
				position:absolute;
				width:100%;
				height:100%;
				overflow:hidden;
				display:flex;

				@include prefixos(justify-content,center);
				@include prefixos(align-items,center);
			}
		}
	}

	.description{
		background: url(#{$imgs}/textura-arroz.jpg) center center;
		padding:30px;
	}

	.title{
		font-family: 'playfair_display_bold';
		color:#493B2D;
		font-size: 35px;

		padding-bottom:10px;
		border-bottom: 1px solid #493B2D;
	}

	.desc{
		// @include resumo(15px,1.3,8);
		margin-bottom:20px;
	}

	.more{
		text-align:center;
	}

	.price{
		display: inline-block;
		padding:6px  12px;
		background:#E9D7A5;
		color: #353335;
		font-size:17px;
		font-family: 'playfair_display_bold';
	}
}

.btn-market-checked{
	border-radius:0;
	display:inline-flex;

	@include button-variant(#353335,#35BF27,#35BF27);

	@include prefixos(align-items,center);

	&:before{
		content:'';
		@extend .icon;
		@include sprite($maket-checked,inline-block);
		margin-right:5px;
	}
}