.topo{
	.bg{
		background: url(#{$imgs}/bg-topo.jpg) center bottom no-repeat;
		padding-bottom:23px;
	}
}

.itens-topo{
	display:flex;

	.box-gray{
		display:flex;

		@include prefixos(align-items,center);

		.icon{
			width:30px;
		}

		.telefone{
			max-width:90px;
		}
	}
}

.box-gray{
	background: rgba(#3B3937, 0.9);
	color:#E9D7A5;
	font-size:12px;
	display: inline-block;
	padding:5px;

	& + .box-gray{
		margin-left:5px;
	}
}

a.box-gray:hover{
	color:#E9D7A5;
}

.logo{
	text-align:center;

	.img-responsive{
		display: inline-block;
	}
}

.categorias-topo{
	background:#FDF7E4;
	position:relative;
	z-index:999;

	.heading-categorias{
		width:250px;
	}

	.fa{
		cursor: pointer;
	}

	ul{
		list-style:none;
		padding:0;
		margin-bottom:0;
	}

	.content{
		position:relative;
		z-index:999;

		> ul {
			> li{

				> a{
					display:block;
					padding:6px 12px;
					overflow:hidden;
					text-overflow:ellipsis;
					max-width:100%;
					white-space:nowrap;
				}
			}
		}
	}
}

.subcat{
	z-index:999;
}

.btn-toggle-cat-top{
	@include button-variant(#000,#EDE6D3,#EDE6D3);
	border-radius:0;
	font-size:13px;
	outline:none;
}

.box-bege{
	background:#E9D7A5;
	max-width:835px;
	margin:0 auto;
	position:relative;
	z-index:5;

	.carrinho{
		display:flex;
		@include prefixos(align-items,center);
		margin:5px 10px;
	}

	.busca{
		margin:0;
		padding-left:40px;

		.form-group{
			display: inline-block;
			margin-bottom:0;
			margin-top:3px;
			width:190px;
		}
	}

	.form-control{
		border-radius:0;
	}

	.btn{
		padding:8px 12px;
		width:40px;
		float:left;
		margin-left:-40px;
		border-radius:0;
	}
}

.btn-inverse{
	background: #000;
	color:#fff;

	&:hover{
		color:#fff;
		background: lighten(#000, 10%);
	}
}