.pane-confirma-compra{
	border-color:#ccc;

	.panel-heading{
		background:#443B2C;
		color:#fff;
	}

	.table{
		border:1px solid #ccc;

		caption{
			padding:8px;
			background:#EEEEEE;
			border-left:1px solid #ccc;
			border-right:1px solid #ccc;
			border-top:1px solid #ccc;
			font-family: 'open_sansbold';
			font-size:16px;
		}
	}
}