.internas-loja{
	margin-top: -37px;
}

.page-header{
	background: url(#{$imgs}/textura-corpo.png);
	margin-top:0;
	padding:57px 0 40px 0;
	text-align:center;
	border:none;
	margin-bottom:40px;

	h1{
		overflow:hidden;
		font-family: 'playfair_display_bold';
		color:#7D654F;
		font-size:26px;
	}

	span{
		display: inline-block;
		padding:0 10px;
		position:relative;

		&:before,
		&:after{
			content: '';
			width:232px;
			height: 13px;
			position:absolute;
			top: calc(50% - 6.5px);
		}

		&:before{
			right:100%;
			background: url(#{$imgs}/adorto-titulo-left.png);
		}

		&:after{
			left:100%;
			background: url(#{$imgs}/adorto-titulo-right.png);
		}
	}
}