.produto{
	max-width:250px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:40px;

	.foto{
		position:relative;
		padding-bottom:100%;
		box-shadow:2px 2px 2px 2px #CECECE;
		margin-bottom:10px;

		.picture{
			position:absolute;
			width:100%;
			height:100%;
			overflow:hidden;
			display:flex;
			@include prefixos(justify-content,center);
			@include prefixos(align-items,center);
		}
	}

	.price{
		text-align:center;

		span{
			display: inline-block;
			padding:6px 12px;
			color: #353335;
			background:#E9D7A5;
			font-family:'playfair_display_bold';
		}
	}

	.desc{
		@include resumo(14px,1.3,3);
		margin:10px 0;
	}

	.btn-mais-informacoes{
		padding:6px 12px;
		color: #353335;
		background:#E9D7A5;
		border-radius:0;
	}

	.btn{
		min-width:180px;
	}
}