.table-status-compra{
	border:1px solid #ccc;
	padding:10px;
	font-size:14px;

	.line{
		@include clearfix;
		margin-left:-10px;
		margin-right:-10px;

		& + .line{
			border-top:1px solid #ccc;
		}
	}

	.col{
		padding:10px;
	}

	h1,h2,h3,
	.h1,.h2,.h3{
		font-family: 'open_sansbold';
		font-size:16px;
	}

	.rastrear{
		display: inline-block;
		margin-right:40px;
	}

	.foto-produto{
		width:100px;
		margin-right:10px;
	}

	.media-title{
		margin-top:0;
	}

	.table{
		margin-bottom:0;
	}

	.valor-2x{
		font-size: 22px;
		font-family: 'open_sansbold';
	}
}