.passos{
	list-style:none;
	padding:0;
	color:#fff;
	font-size:12px;
	font-family: 'open_sansbold';
	display:flex;
	@include prefixos(flex-wrap,wrap);
	@include prefixos(justify-content,center);


	li{
		position:relative;
		height:50px;
		width:144px;
		display:flex;
		margin-bottom:25px;

		@media (max-width:$screen-xs-max){
			margin-left:50px;
			margin-right:50px;
		}

		@media (min-width:$screen-sm-min) and (max-width: $screen-sm-max){
			margin-right:50px;
		}

		@media (min-width:$screen-md-min){
			& + li{
				width:169px;
				padding-left:25px;
			}
		}

		span{
			display:flex;
			@include prefixos(align-items,center);
			@include prefixos(justify-content,center);
			text-align:center;
			padding:0 5px;
			width: calc(100% - 25px);
		}

		&:before{
			content:'';
			border-top:25px solid transparent;
			border-bottom:25px solid transparent;
			position:absolute;
			left:100%;
			z-index:2;
		}
	}

	.number{
		font-size:2em;
		width: 25px;
	}
}

$passos-variant: (
	"passo1" : #C3C3C3 0,
	"passo2" : #B2B2B2 9%,
	"passo3" : #888888 18%,
	"passo4" : #575757 24%,
	"passo5" : #2F2F2F 32%
);

@each $passo,$corBase in $passos-variant{
	.#{$passo}{
		background: $corBase;

		&:before{
			border-left:25px solid  nth($corBase, 1);
		}

		&.success{
			background: darken($brand-success, nth($corBase, 2));
			&:before{
				border-left:25px solid  darken($brand-success, nth($corBase, 2));
			}
		}

		&.danger{
			background: darken($brand-danger, nth($corBase, 2));
			&:before{
				border-left:25px solid  darken($brand-danger, nth($corBase, 2));
			}
		}

		&.warning{
			background:darken($brand-warning, nth($corBase, 2));
			&:before{
				border-left:25px solid  darken($brand-warning, nth($corBase, 2));
			}
		}
	}
}