.popup__cookies{
	position: fixed;
	width: 100%;
	padding: 15px 0;
	left: 0;
	bottom: 0;
	z-index: 999;
	background-color: #FFF;
	box-shadow: 0 0 8px rgba(#000,.8);

	&:not(.popup--shown) {
		display: none;
	}

	&.popup--shown{
		animation: showPopup 1s linear;
	}

	&.popup--hide{
		animation: hidePopup 1s linear;
	}

	a{
		text-decoration: underline;
	}

	.row{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	@media (max-width: $screen-md-max) {
		text-align: center;

		.row{
			justify-content: center;
		}

		.btn{
			min-width: 250px;
		}
	}
}

@keyframes showPopup{
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes hidePopup{
	100% {
		transform: translateY(100%);
	}

	0% {
		transform: translateY(0);
	}
}