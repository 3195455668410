#cadastro{
	border:1px solid #ccc;

	.heading{
		background:#5C5A5B;
		padding:10px;
		color:#fff;
	}

	h1{
		font-size: 16px;
		font-family: 'open_sansbold';
	}

	h1,p{
		margin:0;
	}

	legend{
		padding:10px;
		background: #F1F1F1;
		border-top:1px solid #ccc;
		border-bottom:1px solid #ccc;

		p{
			font-size:13px;
		}

		&.bege{
			background:#EDDFB6;
		}
	}

	.content{
		padding:30px;
	}

	.radio{
		display: inline-block;
		margin-right:15px;
		cursor:pointer;
	}
}

#enderecoAlternativo{
	display: none;
}

.field-effect{
	.form-group{
		position:relative;
		padding-top:17px;

		label{
			padding:0 10px;
			position:absolute;
			margin-bottom:0;
			font-size:13px;
			line-height:17px;
			top:25px;
			@include transition(all 0.3s linear);
		}

		&:after{
			content:'';
			display:block;
			width:0px;
			height:2px;
			position:relative;
			top:-1px;
			background:$brand-success;
			margin:0 auto;
			@include transition(all 0.3s linear);
		}

		&.focus{
			label{
				top:0;
			}

			&:after{
				width:100%;
			}
		}

		&.has-success{
			color: $brand-success;

			&:before{
				content:'';
				font-family:FontAwesome;
				font-size:20px;
				line-height:32px;
				bottom:0;
				display: inline-block;
				position:absolute;
				right:5px;
				bottom:5px;
			}
		}

		&.has-error{
			color: $brand-danger;

			&:before{
				content:'';
				font-family:FontAwesome;
				font-size:20px;
				line-height:32px;
				bottom:0;
				display: inline-block;
				position:absolute;
				right:5px;
				bottom:5px;
			}

			&:after{
				width:100%;
				background:$brand-danger;
			}
		}
	}

	.form-control{
		border-radius:0;
	}

}

.radio{
	padding-left:20px;
}

.btn-brown{
	@include button-variant(#fff,#373536,#373536);
}

.quero-cadastar{
	@include button-variant(#fff,#D3AC4D,#D3AC4D);
	font-size:18px;
}