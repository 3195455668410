.meus-pedidos-login{
	max-width:330px;
	margin-left:auto;
	margin-right:auto;

	.panel-heading{
		background:#373333;
		color:#FFF;
	}

	.panel-body{
		background:#ECEAE8;
	}

	.form-group{
		position:relative;
	}

	.form-control{
		padding-left:30px;
	}

	.fa{
		position:absolute;
		left:0;
		padding-left:5px;
		height:100%;
		line-height:32px;
		color:#C5CFD1;
	}
}