$en: -0px -0px 26px 18px;
$br: -0px -18px 26px 22px;
$es: -0px -40px 26px 22px;
$telefone-t: -0px -62px 26px 24px;
$users: -0px -86px 24px 24px;
$estoque-disponivel: -0px -110px 25px 24px;
$estoque-nao-disponivel: -0px -134px 25px 24px;
$estoque: -0px -158px 25px 24px;
$market-car: -0px -182px 30px 27px;
$gift: -0px -209px 30px 32px;
$maket-checked: -0px -241px 44px 34px;
$envelopes: -0px -275px 39px 34px;
$next-banner: -0px -309px 43px 43px;
$prev-banner: -0px -352px 43px 43px;
$comments: -0px -395px 51px 51px;
@mixin sprite-width($sprite) {
  width: nth($sprite, 3);
}
@mixin sprite-height($sprite) {
  height: nth($sprite, 4);
}
@function sprite-width($sprite) {
  @return nth($sprite, 3);
}
@function sprite-height($sprite) {
  @return nth($sprite, 4);
}
@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 1);
  $sprite-offset-y: nth($sprite, 2);
  background-position: $sprite-offset-x $sprite-offset-y;
}
@mixin sprite($sprite, $display: block) {
  @include sprite-position($sprite);
  background-repeat: no-repeat;
  overflow: hidden;
  display: $display;
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}
.icon {
  background-image: url(../images/sprite.png);
}