@media (max-width:$screen-xs-max){
	// Celphones - < 768

	.itens-topo{
		margin-bottom:20px;

		@include prefixos(justify-content,center);

		.box-gray:last-child{
			display: none;
		}
	}

	#categorias-topo{

		&.active{
			.content,
			&:before{
				left:0;
			}
		}

		&:before{
			content:'';
			position:fixed;
			height:100%;
			width:100%;
			top:0;
			z-index:998;
			background:rgba(#000,0.6);
			left:-100%;
			@include transition(all 0.3s linear);
		}

		.content{
			position:fixed;
			width:220px;
			height:100%;
			background:#fff;
			top:0;
			left:-300px;
			z-index:999;
			box-shadow: 0 0 8px #000;
			overflow:auto;
			@include transition(all 0.3s linear);

			> ul {

				&:last-child{
					border-bottom:1px solid #ccc;
				}

				> li{
					padding-right:40px;
					@include clearfix;

					border-top:1px solid #ccc;

					.fa{
						width:40px;
						float:left;
						padding:9px 12px;
						border-left:1px solid #ccc;
						float:right;
						margin-right:-40px;

						&:before{
							content: '';
						}
					}
				}
			}
		}

		.subcat{
			display: none;
			background:#FDF7E4;
			margin-right:-40px;
			border-top:1px solid #ccc;

			a{
				display: block;
				padding:6px 12px;
			}

			li:hover,
			.active{
				background:#EDE6D3;
			}

			&.in{
				display:block;
			}
		}
	}

	.box-bege{
		padding:10px;

		.login{
			padding-left:40px;
			margin-bottom:10px;

			.icon{
				width:40px;
				float:left;
				margin-left:-40px;
			}
		}

		.carrinho{
			margin-bottom:10px;
		}
	}

	.box-destaque{
		.foto{
			margin-bottom:20px;
		}

		.foto,
		.description{
			box-shadow:0 0 4px 5px rgba(#000,0.06);
		}
	}

	.vitrine-index{
		.produto-vitrine{
			margin-left:auto;
			margin-right:auto;
			max-width:270px;

			& + .produto-vitrine{
				border-top:1px solid #ccc;
				padding-top:30px;
			}
		}
	}

	.gv8{
		margin:0 auto 10px auto;
	}

	.creditos{
		text-align:center;
	}
}