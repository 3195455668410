#banner{
	.owl-wrapper-outer{
		position:relative;
		z-index:2;
	}

	.item {
		img,
		.img-responsive{
			margin-left:auto;
			margin-right:auto;
		}
	}

	.owl-controls{
		position:absolute;
		top:0;
		height:100%;
		width:100%;
		display:flex;

		@include prefixos(align-items,center);

		.owl-buttons{@extend .container;}

		.owl-prev{
			float:left;
			position:relative;
			z-index:4;
		}

		.owl-next{
			float:right;
			position:relative;
			z-index:4;
		}
	}
}