.minha-conta{
	border:1px solid #ccc;

	h1{
		margin:0;
	}

	.panel-heading{
		background:#5C5A5B;
		color:#fff;
	}

	.header{
		background:#F1F1F1;
		margin:0 -15px;
		padding:10px;
		margin-bottom:15px;
		border-top:1px solid #ccc;
		border-bottom:1px solid #ccc;

		&.bege{
			background:#EDDFB6;
			margin-top:-15px;
		}

		h2{
			font-size:16px;
			font-family: 'open_sansbold';
		}

		p{
			font-size:13px;
		}

		h2,
		p{
			margin:0;
		}
	}

	form{
		padding-bottom:40px;
	}

}

.btn-altera-dados{
	font-size:20px;
	font-family: 'open_sansbold';
	@include button-variant(#555557,#EDDFB6,#EDDFB6);
}