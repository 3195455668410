#destaques{
	padding:0 39px;
	max-width:864px;
	margin-bottom:43px;
	margin-left:auto;
	margin-right:auto;

	.ctrls{
		position:absolute;
		top:90px;
	}

	.prev-banner{
		left:0;
	}

	.next-banner{
		right:0;
	}
}

.prod-des{
	width:220px;
	margin-left:auto;
	margin-right:auto;
	text-align:center;

	.foto{
		height:220px;
		background:#FDFCF7;
		border:5px solid #FDFCF7;
		overflow:hidden;

		display:flex;
		@include prefixos(justify-content,center);
		@include prefixos(align-items,center);
	}

	.price{
		margin-bottom:10px;

		span{
			display: inline-block;
			padding:6px 12px;
			background:#E9D7A5;
			color:#353335;
			font-family:'playfair_display_bold';
			font-size:17px;
		}
	}

	.desc{
		@include resumo(13px,1.3,3);
		margin-bottom:10px;
	}

	.buttons{
		width:180px;
		margin:0 auto;

		& > *{
			width:100%;
		}
	}

	.btn-mais-informacoes{
		display: inline-block;
		font-size:13px;
		@include button-variant(#353335,#E9D7A5,#E9D7A5);
	}

}
.btn-market-ckecket-2{
	box-shadow: 0 0 8px rgba(#000,0.6) inset;
	display:inline-flex;
	border-radius:0;

	@include prefixos(align-items,center);

	&:before{
		content:'';
		@extend .icon;
		@include sprite($maket-checked,inline-block);
		margin-right:5px;
	}

	&:hover{
		background:#35BF27;
	}
}