@media (min-width:$screen-md-min){
	// Desktop - >= 990px

	.itens-topo{
		@include prefixos(justify-content,flex-end);
	}

	.categorias-topo .container{
		display:flex;

		.content{
			width: calc(100% - 250px);
		}

		.content > ul{
			list-style:none;
			padding:0;

			& > li{
				padding: 0 5px;
				text-align:center;

				& > span{
					display: none;
				}
			}
		}

		.subcat{
			position:absolute;
			display: none;
			text-align:left;

			&.active{
				display: block;
			}

			ul{padding:10px;
			}
		}
	}

	.categoria-principal{
		display:flex;
		@include prefixos(justify-content,space-between);

		> li{
			width:25%;
		}

		> li:hover{
			background:#EDE6D3;

			&:after{
				content:'';
				display:block;
				position:absolute;
				border-left:10px solid transparent;
				border-right:10px solid transparent;
				border-bottom:10px solid #fff;
				top:100%;
				margin-left: calc(12.5% - 10px);
				z-index:2;
			}

			&:before{
				content:'';
				display:block;
				position:absolute;
				border-left:11px solid transparent;
				border-right:11px solid transparent;
				border-bottom:11px solid rgba(#000,0.6);
				top:100%;
				margin-left: calc(12.5% - 11px);
				z-index:1;
				margin-top:-1px;
			}

			.subcat{
				display: block;

				ul{
					@include prefixos(column-count,3);
				}
			}
		}

	}

	.subcat{
		padding-top:10px;
		width:720px;
		left:0;

		ul{
			box-shadow: 0 0 4px rgba(#000,0.6);
			@include prefixos(column-count,3);
			background:#fff;

			a{
				display: inline-block;
				padding:5px 10px;
				max-width:100%;
				white-space:nowrap;
				text-overflow:ellipsis;
				overflow:hidden;
			}
		}
	}

	.categoria-secundaria{
		display:none;
		position:absolute;
		width:250px;
		right:100%;
		top:100%;
		border-top:1px solid rgba(#000,0.2);

		background:#EDE6D3;

		> li:hover,
		> .active{
			background: darken(#EDE6D3,10%);
			position:relative;

			.subcat{
				display: block;
				left:100%;
				top:0;
				width:700px;
				margin-left:15px;
				padding-top:5px;

				&:before{
					content:'';
					border:11px solid transparent;
					border-right-color:rgba(#000,0.4);
					right:100%;
					position:absolute;
					top:10px;
				}

				&:after{
					content:'';
					border:10px solid transparent;
					border-right-color:#fff;
					top:11px;
					right:100%;
					position:absolute;
				}
			}
		}
	}

	.categorias-topo.active{
		.categoria-secundaria{
			display: block;
		}
	}

	.box-bege{
		display:flex;

		@include prefixos(justify-content,center);
		@include prefixos(align-items,center);

		.login{
			padding:5px 10px;
			display:flex;

			@include prefixos(justify-content,center);
			@include prefixos(align-items,center);

			.users{
				float:left;
			}
		}
	}

	.newsletter .container{
		display: flex;
		@include prefixos(align-items,center);
		label{
			margin:0 5px;
		}

		.form-group{
			margin-bottom:0;
			padding: 0 10px;
		}
	}

	.box-destaque{
		display:flex;

		.foto{
			width:512px;
		}

		.description{
			width:350px;
		}
	}

	#destaques{
		.item{
			.col-sm-6{
				&:first-child{
					.prod-des{
						float:left;
					}
				}
				&:last-child{
					.prod-des{
						float:right;
					}
				}
			}
		}
	}

	.vitrine-index{
		.produto-vitrine{
			width:25%;
			float:left;

			&:nth-child(4) ~ .produto-vitrine{
				border-top:1px solid #ccc;
				padding-top:30px;
			}
		}
	}

	.creditos{
		text-align:right;
	}

	.gv8{
		float:left;
		margin-top:10px;
	}

	.table-status-compra{
		.col{
			float:left;
		}

		.col-3{
			width:33.333%;
		}
	}
}