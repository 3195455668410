.portifolio{
	max-width:310px;
	margin-left:auto;
	margin-right:auto;

	.toggle-content{
		@include clearfix;
		padding-left:30px;
		margin-bottom:0;

		.fa:before{
			margin-right:10px;
		}

		li{
			list-style:none;
			width:50%;
			float:left;
			height:32px;
			border:1px solid #ccc;
			cursor:pointer;
			position:relative;
			padding:5px 10px;
			text-align:center;
			padding-right:30px;
			font-size:16px;

			&:hover,
			&.active{
				background: #eee;
				color:red;

				&:after{
					border-right-color:#eee;
					border-bottom-color:#eee;
				}
			}

			&:after{
				content:'';
				border:15px solid #fff;
				border-left-color:transparent;
				border-top-color:transparent;
				z-index:2;
				position:absolute;
				right:100%;
				margin-left:-1px;
				top:0;
			}

			&:before{
				content:'';
				border:16px solid #ccc;
				border-left-color:transparent;
				border-top-color:transparent;
				z-index:1;
				position:absolute;
				top:0;
				right:100%;
				margin-right:1px;
			}
		}
	}

	.section{
		display:none;

		&.active{
			display:block;
		}
	}

	.foto-g{
		margin-bottom:10px;
	}

	.album-fotos{
		display:flex;
		@include prefixos(flex-wrap,wrap);
		@include prefixos(align-items,center);
		margin-left:-5px;
		margin-right:-5px;

		.item{
			width:25%;
			padding:5px;
		}
	}

	#videos{
		position:relative;
		padding-bottom:100%;
		border:1px solid #ccc;

		iframe{
			position:absolute;
			width:100%;
			height:100%;
		}
	}
}

.deatalhes-preco{
	padding-bottom:10px;
	margin-bottom:20px;

	border-bottom:1px solid #ccc;
	.por{
		font-size:1.4em;
		color:#61B026;
		font-weight:bold;
	}


	span{
		display: block;
	}

	small{
		display: block;
		margin-top:15px;
	}
}


.disponibilidade{
	font-size:10px;
	.icon{
		float:left;
	}

	.qtde{
		display: block;
	}

	&.disponivel{
		color:#2C8A4E;

		.estoque{
			@include sprite($estoque-disponivel, inline-block);
		}

		.disp{
			display: block;
		}

		.nodisp{
			display: none;
		}
	}

	&.nao-disponivel{
		color:#FF0000;

		.estoque{
			@include sprite($estoque-nao-disponivel, inline-block);
		}

		.disp{
			display: none;
		}

		.nodisp{
			display: block;
		}
	}
}

.panel-bege{
	border:1px solid #ccc;

	.panel-heading{
		background:#D3AC4D;
		color:#fff;
	}
}

.comments{
	@include sprite($comments);
	position:absolute;
}

#avalicoes{
	padding-left: 70px;
}

.avaliacao{
	unicode-bidi:bidi-override;
	direction: rtl;
	text-align:left;
}

.estrela-avaliacao{
	font: normal normal normal 17px/1 FontAwesome;
	width: 1.5em;
	height: 1em;
	position: relative;
	cursor: pointer;

	&:before{
		content: "";
	}

	&:hover,
	&.active,
	&:hover ~ .estrela-avaliacao,
	&.active ~ .estrela-avaliacao{
		color: gold;
		text-shadow:0 0 4px rgba(#000,0.8);

		&:before{
			content: "";
		}
	}
}

.redes-sociais{
	font-size:20px;

	a{
		display: inline-flex;
		width:2em;
		height:2em;
		border-radius:100%;

		@include prefixos(align-items,center);
		@include prefixos(justify-content,center);

		& + a{
			margin-left:10px;
		}

		color:#FFF;

		&.facebook{
			background:#4B65A3;
			&:hover{
				background:darken(#4B65A3, 10%);
			}
		}

		&.twitter{
			background:#55ABED;
			&:hover{
				background:darken(#55ABED, 10%);
			}
		}
		&.google-plus{
			background:#DD4B39;
			&:hover{
				background:darken(#DD4B39, 10%);
			}
		}
		&.pinterest{
			background:#DB0000;
			&:hover{
				background:darken(#DB0000, 10%);
			}
		}

		&:hover{
			text-decoration:none;

			@include animation(pulse 0.6s);
		}
	}
}